import axios from '@axios'
import { useAdminEventStore } from "@/modules/admin/events/adminEventStore"

export const useAdminQuestionStore = defineStore('admin-question-store', () => {
  const adminEventStore = useAdminEventStore()

  const totalQuestions = reactive({
    count: null,
    answered: null,
    unanswered: null,
  })

  const { selectedEvent } = storeToRefs(adminEventStore)

  watch(selectedEvent, newSelectedEvent => {
    if(!newSelectedEvent){
      totalQuestions.count = null
      totalQuestions.answered = null
      totalQuestions.unanswered = null

      return
    }
    getCounts()
  }, {
    immediate: true,
    post: "flush",
  })

  function getCounts() {
    axios.get(`/events/${selectedEvent.value.Id}/questionsCounts`, {
      overlay: false,
    })
      .then(result => {
        totalQuestions.count = result.data.count
        totalQuestions.answered = result.data.answered
        totalQuestions.unanswered = result.data.unanswered
      })
  }

  return {
    totalQuestions,
    getCounts,
  }

})
