import axios from "@axios"

export const useAdminMembersStore = defineStore('admin-members-store', () => {
  const selectedMember = ref(null)

  function toggleSelectedMember(member) {

    console.log('here', member)

    if (selectedMember.value && member.Id !== selectedMember.value.Id) {
      selectedMember.value = member

      return
    }
    selectedMember.value = selectedMember.value ? null : member
  }

  async function doDelete(memberId){
    await axios.delete(`/members/${memberId}`)
  }

  async function fetchMember(memberId){
    return axios.get(`/members/${memberId}`)
  }

  async function save(data){

    return axios.request({
      method: data.Id ? 'PUT' : 'POST',
      url: data.Id ? `/members/${data.Id}` : `/members`,
      data,
    })
  }

  return {
    selectedMember,
    toggleSelectedMember,
    doDelete,
    fetchMember,
    save,
  }
}, {
  persist: true,
})
