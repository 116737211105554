import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import { isUserLoggedIn } from './utils'
import routes from '~pages'

import {useAbility} from '@casl/vue'

console.log(import.meta.env.BASE_URL)

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...setupLayouts(routes),
  ],
})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach((to, from, next) => {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}')
    const userRole = userData && userData.role ? userData.role : null

    if(userRole && userRole === 'admin') {
        next()
        return
    }
    if(userRole && userRole === 'customer'){
        next()
        return
    }

    const { can } = useAbility()

    if(to.meta.subject && !can(to.meta.action || 'list', to.meta.subject)){
        next('/forbidden')
        return
    }
    next()
    // const isLoggedIn = isUserLoggedIn()
    // const userData = JSON.parse(localStorage.getItem('userData') || '{}')
    // const userRole = userData && userData.role ? userData.role : null
    // if (canNavigate(to) || (userRole === 'user' && to.path.includes('events'))) {
    //     if (to.meta.redirectIfLoggedIn && isLoggedIn) next('/')
    //     else next()
    // } else {
    //     if (isLoggedIn) {
    //         next({name: 'not-authorized'})
    //     } else {
    //         next({name: 'login', query: {to: to.name !== 'index' ? to.fullPath : undefined}})
    //     }
    // }
})

export default router
