<script setup>
import {useAdminStore} from "@/store/adminStore"

const userData = JSON.parse(localStorage.getItem('userData') || 'null')
const router = useRouter()
const adminStore = useAdminStore()

const avatarBadgeProps = {
    dot: true,
    location: 'bottom right',
    offsetX: 3,
    offsetY: 3,
    color: 'success',
    bordered: true,
}

const logout = () => {
    localStorage.removeItem('userData')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userAbilities')
    adminStore.doLogout()
    // router.push('/admin/login')

}
</script>

<template>
    <VBadge v-bind="avatarBadgeProps">
        <VAvatar
            class="cursor-pointer"
            color="primary"
            variant="tonal"
        >
            <VImg
                v-if="userData && userData.avatar"
                :src="userData.avatar"
            />
            <VIcon
                v-else
                icon="mdi-account-outline"
            />

            <!-- SECTION Menu -->
            <VMenu
                activator="parent"
                width="230"
                location="bottom end"
                offset="14px"
            >
                <VList>
                    <!-- 👉 User Avatar & Name -->
                    <VListItem>
                        <template #prepend>
                            <VListItemAction start>
                                <VBadge v-bind="avatarBadgeProps">
                                    <VAvatar
                                        color="primary"
                                        size="40"
                                        variant="tonal"
                                    >
                                        <VImg
                                            v-if="userData && userData.avatar"
                                            :src="userData.avatar"
                                        />
                                        <VIcon
                                            v-else
                                            icon="mdi-account-outline"
                                        />
                                    </VAvatar>
                                </VBadge>
                            </VListItemAction>
                        </template>

                        <VListItemTitle class="font-weight-medium">
                            <template v-if="userData.role === 'user'">
                                {{ userData.customer.Name }} {{ userData.customer.Surname }}
                            </template>
                            <template v-else>
                                {{ userData.name }}
                            </template>

                        </VListItemTitle>
                        <VListItemSubtitle>
                            <template v-if="userData.role !== 'user'">
                                {{ userData.role }}
                            </template>

                        </VListItemSubtitle>
                    </VListItem>

                    <VDivider class="my-2"/>

                    <!-- 👉 Logout -->
                    <VListItem
                        link
                        @click="logout"
                    >
                        <template #prepend>
                            <VIcon
                                class="me-2"
                                icon="mdi-logout-variant"
                                size="22"
                            />
                        </template>

                        <VListItemTitle>Logout</VListItemTitle>
                    </VListItem>
                </VList>
            </VMenu>
            <!-- !SECTION -->
        </VAvatar>
    </VBadge>
</template>
