import { ref } from 'vue'

const alert = ref(false)
const alertType = ref(null)
const alertMessage = ref(null)

export default function () {

  const showAlert = (type, message) => {
    alert.value = true
    alertType.value = type
    alertMessage.value = message
  }

  return {
    alert,
    showAlert,
    alertType,
    alertMessage,
  }
}
