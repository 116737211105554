import axios from "@axios"
import { useAppAbility } from "@/plugins/casl/useAppAbility"
import { useAdminEventStore } from "@/modules/admin/events/adminEventStore"

export const useAdminStore = defineStore("admin-store", () => {
  const router = useRouter()
  const accessToken = ref(null)
  const userData = ref(null)
  const userAbilities = ref(null)
  const ability = useAppAbility()
  const eventStore = useAdminEventStore()

  async function doLogin(email, password) {
    const r = await axios.post('login', {
      email,
      password,
    })

    accessToken.value = r.data.accessToken
    userData.value = r.data.userData
    userAbilities.value = r.data.userAbilities

    localStorage.setItem('userAbilities', JSON.stringify(r.data.userAbilities))
    localStorage.setItem('userData', JSON.stringify(r.data.userData))
    localStorage.setItem('accessToken', JSON.stringify(r.data.accessToken))

    ability.update(userAbilities.value)

    if (userData.value.role === 'user') {

      if(userData.value.event){
        eventStore.selectedEvent = userData.value.event
        await router.push('/admin/questions')

        return
      }else{
        eventStore.selectedEvent = null
      }
    }else if (userData.value.role === 'office') {
        await router.push('/admin/members')

        return
    }

    await router.push('/admin/events')

  }

  async function doLogout() {
    return new Promise(resolve => {
      accessToken.value = null
      userData.value = null
      userAbilities.value = null

      resolve()
    })
      .then(() => router.push(`/admin/login`))
  }

  const isLoggedIn = computed(() => !!accessToken.value)

  return {
    accessToken,
    userData,
    userAbilities,
    ability,
    isLoggedIn,

    doLogin,
    doLogout,
  }
}, {
  persist: true,
})
