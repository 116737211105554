import axios from "@axios"

export const useAdminDomainsStore = defineStore('admin-domains-store', () => {
  const selectedDomain = ref(null)

  function toggleSelectedDomain(domain) {

    if (selectedDomain.value && domain.Id !== selectedDomain.value.Id) {
      selectedDomain.value = domain

      return
    }
    selectedDomain.value = selectedDomain.value ? null : domain
  }

  async function doDelete(domainId){
    await axios.delete(`/domains/${domainId}`)
    if(selectedDomain.value && domainId === selectedDomain.value.Id){
      selectedDomain.value = null
    }
  }

  async function fetchDomain(domainId){
    return axios.get(`/domains/${domainId}`)
  }

  async function save(data){

    return axios.request({
      method: data.Id ? 'PUT' : 'POST',
      url: data.Id ? `/domains/${data.Id}` : `/domains`,
      data,
    })
  }


  return {
    selectedDomain,

    toggleSelectedDomain,
    fetchDomain,
    doDelete,
    save,
  }
}, {
  persist: true,
})
