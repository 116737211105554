import {useAdminEventStore} from "@/modules/admin/events/adminEventStore"
import {useAdminQuestionStore} from "@/modules/admin/questions/adminQuestionStore"
import {useAdminMembersStore} from "@/modules/admin/members/adminMembersStore"
import {useAdminCustomersStore} from "@/modules/admin/customers/adminCustomersStore"
import {useAdminDomainsStore} from "@/modules/admin/domains/adminDomainsStore"

export const useRoutes = () => {

    const adminEventStore = useAdminEventStore()
    const adminQuestionStore = useAdminQuestionStore()
    const adminMembersStore = useAdminMembersStore()
    const adminCustomersStore = useAdminCustomersStore()
    const adminDomainsStore = useAdminDomainsStore()

    return [
        {
            heading: 'Domains',
            subject: 'domains',
            action: 'list',
        },
        {
            key: 'domains',
            title: 'Domains',
            icon: {icon: 'mdi-globe'},
            subject: 'domains',
            action: 'list',
            to: {path: '/admin/domains'},
            badgeContent: computed(() => adminDomainsStore.selectedDomain ? `#${adminDomainsStore.selectedDomain.Id}` : null),
            badgeClass: 'bg-success',
        },
        {
            heading: 'Σεμιναρια',
            subject: 'events',
            action: 'list',
        },
        {
            key: 'events',
            title: 'Αρχείο Σεμιναρίων',
            icon: {icon: 'mdi-monitor-screenshot'},
            subject: 'events',
            action: 'list',
            to: {path: '/admin/events'},
            badgeContent: computed(() => adminEventStore.selectedEvent ? `#${adminEventStore.selectedEvent.Id}` : null),
            badgeClass: 'bg-success',
        },
        {
            key: 'eventDurations',
            title: 'Διάρκεια Σεμιναρίων',
            icon: {icon: 'mdi-clock-time-two-outline'},
            subject: 'eventDurations',
            action: 'list',
            to: {path: '/admin/eventDurations'},
        },
        {
            heading: 'Αίθουσα',
            subject: 'desktop',
            action: 'list',
        },
        {
            key: 'desktop',
            title: 'Ειδοποιήσεις',
            icon: {icon: 'mdi-bell-outline'},
            subject: 'desktop',
            action: 'list',
            to: {path: '/admin/desktop/notifications'},
        },
        {
            heading: 'Ερωτήματα',
            subject: 'questions',
            action: 'list',
        },
        {
            key: 'questions',
            title: 'Ερωτήματα',
            icon: {icon: 'mdi-account-outline'},
            subject: 'questions',
            action: 'list',
            to: {path: '/admin/questions'},
            badgeContent: computed(() => adminEventStore.selectedEvent && adminQuestionStore.totalQuestions ? `${adminQuestionStore.totalQuestions.answered}` : null),
            badgeClass: 'bg-success',
        },
        {
            heading: 'Χρήστες',
            subject: 'customers',
            action: 'list',
        },
        {
            key: 'customers',
            title: 'Αρχείο Χρηστών',
            icon: {icon: 'mdi-account-outline'},
            subject: 'customers',
            action: 'list',
            to: {path: '/admin/customers'},
            badgeContent: computed(() => adminCustomersStore.selectedCustomer ? `#${adminCustomersStore.selectedCustomer.Id}` : null),
            badgeClass: 'bg-success',
        },
        {
            key: 'eventCustomers',
            title: 'Χρήστες Αίθουσας',
            icon: {icon: 'mdi-monitor-account'},
            subject: 'eventCustomers',
            action: 'list',
            to: {path: '/admin/eventCustomers'},
        },
        {
            key: 'eventUsers',
            title: 'Χρήστες Διαχείρισης',
            icon: {icon: 'mdi-monitor-star'},
            subject: 'eventUsers',
            action: 'list',
            to: {path: '/admin/eventUsers'},
        },
        {
            heading: 'Μέλη',
            subject: 'members',
            action: 'list',
        },
        {
            key: 'members',
            title: 'Αρχείο Μελών',
            icon: {icon: 'mdi-account-star-outline'},
            subject: 'members',
            action: 'list',
            to: {path: '/admin/members'},
            badgeContent: computed(() => adminMembersStore.selectedMember ? `#${adminMembersStore.selectedMember.Id}` : null),
            badgeClass: 'bg-success',
        },
        {
            key: 'memberRegistrations',
            title: 'Συνδρομές Μελών',
            icon: {icon: 'mdi-account-details-outline'},
            subject: 'memberRegistrations',
            action: 'list',
            to: {path: '/admin/memberRegistrations'},
        },
        {
            heading: 'FAQ',
            subject: 'faq',
            action: 'list',
        },
        {
            key: 'faqcategory',
            title: 'Κατηγορίες',
            icon: {icon: 'mdi-frequently-asked-questions'},
            subject: 'faqcategory',
            action: 'list',
            to: {path: '/admin/faqcategories'},
        },
        {
            key: 'faqquestion',
            title: 'Ερωτήσεις',
            icon: {icon: 'mdi-frequently-asked-questions'},
            subject: 'faqquestion',
            action: 'list',
            to: {path: '/admin/faqquestions'},
        },
        {
            heading: 'Ρυθμίσεις',
            subject: 'settings',
            action: 'list',
        },
        {
            key: 'settings',
            title: 'Ρυθμίσεις συστήματος',
            icon: {icon: 'mdi-settings-outline'},
            subject: 'settings',
            action: 'list',
            to: {path: '/admin/settings'},
        },
        {
            key: 'settings',
            title: 'Ρυθμίσεις σεμιναρίων',
            icon: {icon: 'mdi-settings-outline'},
            subject: 'settings',
            action: 'list',
            to: {path: '/admin/settings/seminars'},
        },
        {
            heading: 'Αναφορές',
            subject: 'reports',
            action: 'list',
        },
        {
            key: 'reports',
            title: 'Αναφορές πωλήσεων',
            icon: {icon: 'mdi-poll'},
            subject: 'salesreports',
            action: 'list',
            to: {path: '/admin/reports/sales'},
        },
        {
            key: 'reports',
            title: 'Συμμετοχές',
            icon: {icon: 'mdi-monitor-account'},
            subject: 'reports/eventCustomers',
            action: 'list',
            to: {path: '/admin/reports/eventCustomers'},
        },
        {
            key: 'reports',
            title: 'Λογιστές',
            icon: {icon: 'mdi-account-star-outline'},
            subject: 'accountants',
            action: 'list',
            to: {path: '/admin/reports/accountants'},
        },
        {
            key: 'reports',
            title: 'Τίτλοι κτήσης',
            icon: {icon: 'mdi-account-star-outline'},
            subject: 'titloiKtisis',
            action: 'list',
            to: {path: '/admin/titloiKtisis'},
        },
        {
            key: 'reports',
            title: 'Συνδρομές Μελών',
            icon: {icon: 'mdi-account-details-outline'},
            subject: 'reports/members',
            action: 'list',
            to: {path: '/admin/reports/members'},
        },
    ]
}
