<template>
  <div />
</template>

<script setup>
const router = useRouter()

window.location.href  = `/customer/login`
</script>

<route lang="yaml">
meta:
  layout: blank
</route>>
