/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import ability from '@/plugins/casl/ability'

// import i18n from '@/plugins/i18n'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import { abilitiesPlugin } from '@casl/vue'
import '@core/scss/template/index.scss'
import '@styles/styles.scss'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import { createHead } from 'unhead'
import { helpers } from './helpers.js'
import CKEditor from '@ckeditor/ckeditor5-vue'
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar"
import "vue3-snackbar/styles"

loadFonts()

// Create vue app
const app = createApp(App)
const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)

// Use plugins
app.use(vuetify)
app.use(pinia)
app.use(router)
app.use(layoutsPlugin)
app.use(CKEditor)
app.use(SnackbarService)

app.component("Vue3Snackbar", Vue3Snackbar)

// app.use(i18n)
app.use(createHead())
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})

app.config.globalProperties.helpers = helpers

// Mount vue app
app.mount('#app')
