import axios from '@axios'

export const useAdminEventStore = defineStore('admin-event-store', () => {
    const selectedEvent = ref(null)
    const filtersModal = ref(false)

    const activeFiltersCount = computed(() => {
        return Object.values(filters).filter(filterParams => !!filterParams.value).length
    })

    const filters = reactive({
        HasPaid: {
            operator: 'eq',
            value: null,
        },
        IsMember: {
            operator: 'eq',
            value: null,
        },
        HasQuestions: {
            operator: 'eq',
            value: null,
        },
        OnDemand: {
            operator: 'eq',
            value: null,
        },
    })

    function clearFilters() {
        filters.HasPaid.value = null
        filters.HasPaid.operator = 'eq'
        filters.IsMember.value = null
        filters.IsMember.operator = 'eq'
        filters.HasQuestions.value = null
        filters.HasQuestions.operator = 'eq'
        filters.OnDemand.value = null
        filters.OnDemand.operator = 'eq'
    }

    function toggleSelectedEvent(evnt) {

        if (selectedEvent.value && evnt.Id !== selectedEvent.value.Id) {
            selectedEvent.value = evnt

            return
        }
        selectedEvent.value = selectedEvent.value ? null : evnt
    }

    async function fetchEvent(eventId) {
        return axios.get(`/events/${eventId}`)
    }

    async function save(data) {

        return axios.request({
            method: data.Id ? 'PUT' : 'POST',
            url: data.Id ? `/events/${data.Id}` : `/events`,
            data,
        })
    }

    async function doDelete(eventId) {
        await axios.delete(`/events/${eventId}`)
        if (selectedEvent.value && eventId === selectedEvent.value.Id) {
            selectedEvent.value = null
        }
    }

    async function updateChatStatus(status) {
        await axios.patch(`/events/${selectedEvent.value.Id}/updateChatStatus`, {
            chatStatus: status,
        })

        const freshEvent = await fetchEvent(selectedEvent.value.Id)

        selectedEvent.value = freshEvent.data
    }

    return {
        selectedEvent,
        filtersModal,
        activeFiltersCount,
        filters,
        clearFilters,
        save,
        toggleSelectedEvent,
        fetchEvent,
        doDelete,
        updateChatStatus,
    }
}, {
    persist: true,
})
