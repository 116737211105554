<script setup>
import { useTheme } from 'vuetify'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import useOverlayLoader from '@/composables/useOverlayLoader.js'
import { useUserStore } from "@/store/userStore"
import { useAdminStore } from "@/store/adminStore"
import { useAppAbility } from "@/plugins/casl/useAppAbility"

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
} = useThemeConfig()

const { global } = useTheme()
const { overlay } = useOverlayLoader()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()

const ability = useAppAbility()

let userAbilities
userAbilities = useUserStore().userAbilities
if (userAbilities) {
  ability.update(userAbilities)
}
userAbilities = useAdminStore().userAbilities
if (userAbilities) {
  ability.update(userAbilities)
}
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
    </VApp>
  </VLocaleProvider>

  <div class="text-center">
    <VOverlay
      :model-value="overlay"
      class="align-center justify-center"
    >
      <VProgressCircular
        color="primary"
        indeterminate
        size="64"
      />
    </VOverlay>
  </div>
</template>
