import axios from "@axios"
import {useAppAbility} from "@/plugins/casl/useAppAbility"
import {helpers} from "@/helpers"

export const useUserStore = defineStore("user-store", () => {
    const router = useRouter()
    const accessToken = ref(null)
    const userData = ref(null)
    const userAbilities = ref(null)
    const ability = useAppAbility()
    const initialized = ref(false)
    const settings = ref([])

    initialized.value = false

    async function printCertificate(email, password) {

        return new Promise(async (resolve, reject) => {

            try {
                const auth = await axios.post('loginCustomer', {
                    email,
                    password,
                })

                //If user is already loggedin, dont log him out
                const tokenPrevVal = accessToken.value

                accessToken.value = auth.data.accessToken
                await helpers.downloadFile(`events/${auth.data.userData.event_id}/printCertificate`, 'certificate.pdf')
                accessToken.value = tokenPrevVal

                resolve()
            } catch (e) {
                accessToken.value = null
                reject(e)
            }
        })
    }
    async function downloadNotes(email, password) {
        await doLogin(email, password, false)
        window.open('/customer/seminar-questions', 'blank')
    }

    async function printReceipt(email, password) {
        return new Promise(async (resolve, reject) => {
            try {
                const auth = await axios.post('loginCustomer', {
                    email,
                    password,
                })

                //If user is already loggedin, dont log him out
                const tokenPrevVal = accessToken.value

                accessToken.value = auth.data.accessToken
                console.log(auth.data.userData)
                await helpers.downloadFile(`eventCustomers/${auth.data.userData.event_customer_id}/printReceipt`, 'Απόδειξη.pdf')
                accessToken.value = tokenPrevVal

                resolve()
            } catch (e) {
                accessToken.value = null
                reject(e)
            }
        })
    }

    async function showAudioAnswers(email, password) {
        return new Promise(async (resolve, reject) => {

            try {
                const auth = await axios.post('loginCustomer', {
                    email,
                    password,
                })

                accessToken.value = auth.data.accessToken

                const event = await axios.get(`events/${auth.data.userData.event_id}`)

                window.open(event.data.DownloadNotesUrl, '_blank')

                // console.log('here', event)

                accessToken.value = null

                resolve()
            } catch (e) {
                accessToken.value = null
                reject(e)
            }
        })
    }

    async function doLogin(email, password, redirect = true) {
        const r = await axios.post('loginCustomer', {
            email,
            password,
        })

        accessToken.value = r.data.accessToken
        userData.value = r.data.userData
        userAbilities.value = r.data.userAbilities
        ability.update(userAbilities)

        if (redirect) {
            window.location.href = '/customer'
        }

    }

    async function doLogout() {
        return new Promise(resolve => {
            accessToken.value = null
            userData.value = null
            userAbilities.value = null
            settings.value.vale = []
            initialized.value = false

            resolve()
        })
            .then(() => window.location.href = `/customer/login`)
    }

    async function initialize() {
        await fetchEventCustomer()

        const el = document.querySelector('.v-application__wrap')

        el.style.backgroundImage = `url(/${userData.value.event_customer.event.WallpaperUrl})`
        el.style.backgroundRepeat = 'no-repeat'
        el.style.backgroundPosition = 'center'
        el.style.backgroundSize = 'cover'

        const result = await axios.get(`/settings/desktop?eventId=${userData.value.event_id}`)

        settings.value = result.data.map((setting, idx) => {
            let image = null
            let order = idx
            let url = `/customer/settings/${setting.Id}`

            if (setting.Parameters) {
                const params = setting.Parameters.split(';').filter(par => !!par)
                for (let param of params) {
                    const val = param.split('=')[1]
                    if (param.includes('icon')) {
                        image = `/Resources/Icons32/${val}`
                    } else if (param.includes('order')) {
                        order = val
                    } else if (param.includes('iframe')) {
                        url = val
                    }
                }
            }

            return {
                ...setting,
                image,
                order,
                url,
            }
        })

        $('#DesktopIcons').find("a").each(function () {
            $(this).attr('href') !== undefined && $(this).AeroWindowLink()
            $('#DesktopIconwindow-video').dblclick()
            $('#DesktopIconwindow-slides').dblclick()
        })

        initialized.value = true
        setInterval(userStore.fetchEventCustomer, 5000)
    }

    async function fetchEventCustomer() {
        return axios.get(`/eventCustomers/${userData.value.event_customer_id}`, {overlay: false})
            .then(r => userData.value.event_customer = r.data)
    }

    async function beginAfterWatch() {
        return axios.post(`/d/eventCustomers/beginAfterWatch`, {overlay: false})
    }

    async function ping() {
        return axios.get(`/events/${userData.value.event_id}/ping`, {overlay: false})
    }

    const videoUrl = computed(() => {
        if (!userData.value.event) return null

        //After on demand
        if (userData.value.event_customer.event.State === 3) {
            return userData.value.event_customer.event.OnDemand_StreamingUrl

            //Before on demand
        } else if (userData.value.event_customer.event.State === 2) {
            return userData.value.event_customer.event.OnDemand_StreamingUrl

            //Live
        } else {
            return userData.value.event_customer.event.StreamingUrl
        }
    })

    const slidesUrl = computed(() => {
        if (!userData.value.event) return null

        //After on demand
        if (userData.value.event_customer.event.State === 3) {
            return userData.value.event_customer.event.OnDemand_SlideShowUrl

            //Before on demand
        } else if (userData.value.event_customer.event.State === 2) {
            return userData.value.event_customer.event.OnDemand_SlideShowUrl

            //Live
        } else {
            return userData.value.event_customer.event.SlideShowUrl
        }
    })

    const isLoggedIn = computed(() => !!accessToken.value)

    // watch(event, newEvent => {
    //
    //   if (!newEvent) return
    //   if (!router.currentRoute.value.path.startsWith('/customer')) return
    //
    //   nextTick(() => {
    //     const el = document.querySelector('.v-application__wrap')
    //
    //     if (newEvent && newEvent.WallpaperUrl) {
    //       el.style.backgroundImage = `url(/${newEvent.WallpaperUrl})`
    //       el.style.backgroundRepeat = 'no-repeat'
    //       el.style.backgroundPosition = 'center'
    //       el.style.backgroundSize = 'cover'
    //     }
    //   })
    //
    // }, {
    //   deep: true,
    //   flush: "post",
    // })

    return {
        accessToken,
        userData,
        userAbilities,
        ability,
        isLoggedIn,
        videoUrl,
        slidesUrl,
        initialized,
        settings,

        fetchEventCustomer,
        ping,
        beginAfterWatch,
        printCertificate,
        printReceipt,
        showAudioAnswers,
        downloadNotes,
        doLogin,
        doLogout,
        initialize,

    }
}, {
    persist: true,
})
