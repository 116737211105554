<template>
  <div
    v-if="adminEventStore.selectedEvent && adminStore.userData.role === 'admin'"
    class="v-col-3"
  >
    <VSelect
      v-model="adminEventStore.selectedEvent.ChatStatus"
      density="compact"
      prepend-inner-icon="mdi-application-edit-outline"
      item-title="text"
      item-value="value"
      variant="solo-filled"
      label="Κατάσταση ερωτημάτων"
      :items="chatStatuses"
      class="mt-2"
      @update:model-value="(v) => adminEventStore.updateChatStatus(v)"
    />
  </div>
</template>

<script setup>
import { useAdminEventStore } from "@/modules/admin/events/adminEventStore"
import { useAdminStore } from "@/store/adminStore"

const adminEventStore = useAdminEventStore()
const adminStore = useAdminStore()

const chatStatuses = [
  {
    text: 'Ανενεργό',
    value: 2,
  },
  {
    text: 'Με προειδοποίηση',
    value: 1,
  },
  {
    text: 'Ελεύθερο',
    value: 0,
  },
]
</script>
