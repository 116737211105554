<script setup>
import { useRoutes } from '@/navigation/vertical'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import useAlert from '@/composables/useAlert'

const navItems = useRoutes()

// Components
import NavbarThemeSwitcher from '@/layouts/components/NavbarThemeSwitcher.vue'
import UserProfile from '@/layouts/components/UserProfile.vue'
import EventChatStatusSwitcher from '@/layouts/components/EventChatStatusSwitcher.vue'

// @layouts plugin
import { VerticalNavLayout } from '@layouts'

const { appRouteTransition, isLessThanOverlayNavBreakpoint } = useThemeConfig()
const { width: windowWidth } = useWindowSize()
const { showAlert, alertType, alertMessage, alert } = useAlert()
</script>

<template>
  <VerticalNavLayout
    :nav-items="navItems"
  >
    <!-- 👉 navbar -->
    <template #navbar="{ toggleVerticalOverlayNavActive }">
      <div class="d-flex h-100 align-center">
        <VBtn
          v-if="isLessThanOverlayNavBreakpoint(windowWidth)"
          icon
          variant="text"
          color="default"
          class="ms-n3"
          size="small"
          @click="toggleVerticalOverlayNavActive(true)"
        >
          <VIcon
            icon="mdi-menu"
            size="24"
          />
        </VBtn>
        <EventChatStatusSwitcher />
        <VSpacer />
        <NavbarThemeSwitcher />
        <UserProfile />
      </div>
    </template>

    <RouterView v-slot="{ Component }">
      <Transition
        :name="appRouteTransition"
        mode="out-in"
      >
        <div>
          <VSnackbar
            v-model="alert"
            :timeout="3000"
            :color="alertType"
            transition="scroll-y-reverse-transition"
            location="top"
          >
            {{ alertMessage }}
            <template #actions>
              <VBtn
                icon
                size="x-small"
                variant="text"
                color="white"
                @click="alert = false"
              >
                <VIcon
                  size="20"
                  icon="mdi-close"
                />
              </VBtn>
            </template>
          </VSnackbar>

          <Component :is="Component" />
        </div>
      </Transition>
    </RouterView>

    <template #footer />
  </VerticalNavLayout>
</template>
