import axios from "@axios"
import axios2 from "axios"
import {useAppAbility} from "@/plugins/casl/useAppAbility"

export const useMemberStore = defineStore("member-store", () => {
    const router = useRouter()
    const accessToken = ref(null)
    const userData = ref(null)
    const userAbilities = ref(null)
    const ability = useAppAbility()

    async function doLogin(email, password) {
        return axios.post('loginMember', {
            email,
            password,
        }).then(async r => {

            accessToken.value = r.data.accessToken
            userData.value = r.data.userData
            userAbilities.value = r.data.userAbilities
            ability.update(userAbilities)

            window.location.href = '/member/dashboard'

        })
    }

    async function doRegister(data) {
        return axios.post('registerMember', data)
    }

    async function doLogout() {
        return new Promise(resolve => {
            accessToken.value = null
            userData.value = null
            userAbilities.value = null

            resolve()
        })
            .then(() => window.location.href = `/member/login`)
    }

    async function doReset(email, captcha, captcha_api) {
        return axios.post('resetMemberPassword', {
            email,
            captcha,
            captcha_api
        })
    }

    async function getCaptchaKey() {
        return axios2.get('/captcha/api/math')
    }

    const isLoggedIn = computed(() => !!accessToken.value)

    return {
        accessToken,
        userData,
        userAbilities,
        ability,
        isLoggedIn,

        doRegister,
        doLogin,
        doLogout,
        doReset,
        getCaptchaKey
    }
}, {
    persist: true,
})
