import axios from 'axios'
import router from '@/router'
import useOverlayLoader from '@/composables/useOverlayLoader.js'
import { useUserStore } from "@/store/userStore"
import { useAdminStore } from "@/store/adminStore"
import { useMemberStore } from "@/store/memberStore"

const { overlay } = useOverlayLoader()

const axiosIns = axios.create({
  baseURL: '/api/',
  maxRedirects: 0,
})

axiosIns.interceptors.request.use(function (config) {

  overlay.value = config.hasOwnProperty('overlay')
    ? config.overlay
    : true

  return config
}, function (error) {
  overlay.value = false

  return Promise.reject(error)
})

// Add a request interceptor
axiosIns.interceptors.request.use(function (config) {

  let accessToken = null

  const allTokens = {
    customer: useUserStore().accessToken,
    member: useMemberStore().accessToken,
    admin: useAdminStore().accessToken,
  }

  if(router.currentRoute.value.path.startsWith('/customer')){
    accessToken = allTokens.customer
  }else if(router.currentRoute.value.path.startsWith('/member')){
    accessToken = allTokens.member
  } else if(router.currentRoute.value.path.startsWith('/admin')){
    accessToken = allTokens.admin
  }

  if(config.hasOwnProperty('as') && config.as){
    config.headers['Authorization'] = `Bearer ${allTokens[config.as]}`
  } else if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`
  }

  return config

}, function (error) {
  overlay.value = false

  return Promise.reject(error)
})

// Add a response interceptor
axiosIns.interceptors.response.use(function (response) {
  overlay.value = false

  return response
}, function (error) {

  if (error.response?.status === 401) {

    const url = new URL(window.location.href)
    const whereToGo = url.pathname.split('/').find(part => ['admin', 'customer', 'member'].includes(part))

    if(['admin', 'member'].includes(whereToGo)){
      window.location.href = `/${whereToGo}/login`
    }else{
      window.location.href = `/customer/login?err`
    }

  }
  overlay.value = false

  return Promise.reject(error)
})

export default axiosIns
