import axios from "@axios"

function addZero(num) {
    if (num < 10) num = '0' + num

    return num
}

export const helpers = {

    formatDate(dateStr) {
        if (!dateStr) return ''
        var date = new Date(dateStr)

        return addZero(date.getDate()) + '/' + addZero(date.getMonth() + 1) + '/' + addZero(date.getFullYear()) + " " + addZero(date.getHours()) + ':' + addZero(date.getMinutes()) + ':' + addZero(date.getSeconds())
    },

    formatDateSimple(dateStr) {
        if (!dateStr) return ''
        var date = new Date(dateStr)

        return addZero(date.getDate()) + '/' + addZero(date.getMonth() + 1) + '/' + addZero(date.getFullYear())
    },


    stateIcon(status) {
        if (status === 1 || status === true) {
            return {
                class: 'text-success',
                icon: 'mdi-check',
            }
        } else {
            return {
                class: 'text-error',
                icon: 'mdi-close',
            }
        }
    },

    async downloadFile(url, filename, as = 'customer', params = {}) {
        const response = await axios.request({
            params,
            as,
            method: 'GET',
            url: url,
            responseType: 'blob',
        })

        const href = window.URL.createObjectURL(response.data)
        const anchorElement = document.createElement('a')

        anchorElement.href = href
        anchorElement.download = filename

        document.body.appendChild(anchorElement)
        anchorElement.click()

        document.body.removeChild(anchorElement)
        window.URL.revokeObjectURL(href)
    },
}
