import axios from "@axios"

export const useAdminCustomersStore = defineStore('admin-customers-store', () => {
  const selectedCustomer = ref(null)

  function toggleSelectedCustomer(customer) {

    if (selectedCustomer.value && customer.Id !== selectedCustomer.value.Id) {
      selectedCustomer.value = customer

      return
    }
    selectedCustomer.value = selectedCustomer.value ? null : customer
  }

  async function doDelete(customerId){
    await axios.delete(`/customers/${customerId}`)
    if(selectedCustomer.value && customerId === selectedCustomer.value.Id){
      selectedCustomer.value = null
    }
  }

  async function fetchCustomer(customerId){
    return axios.get(`/customers/${customerId}`)
  }

  async function save(data){

    return axios.request({
      method: data.Id ? 'PUT' : 'POST',
      url: data.Id ? `/customers/${data.Id}` : `/customers`,
      data,
    })
  }


  return {
    selectedCustomer,

    toggleSelectedCustomer,
    fetchCustomer,
    doDelete,
    save,
  }
}, {
  persist: true,
})
